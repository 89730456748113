<template>
  <main-layout
    :key="cluster.id"
    :entity-name="cluster.name"
    :entity-logo="logo"
    :parent-entity-name="organization.name"
    :parent-entity-route="{ name: 'organization-projects', params: { organizationId } }"
    :sidebar-items="filteredSidebarItems"
  >
    <router-view :key="($route.params.clusterId || '')" />
  </main-layout>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Cluster',
  components: {
    MainLayout: () => import('@/components/MainLayout'),
  },
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, required: true },
  },
  computed: {
    ...mapGetters({ organization: 'organization/data' }),
    ...mapGetters({ cluster: 'cluster/data' }),
    ...mapGetters('cluster', ['sidebarItems']),
    ...mapGetters('organization', ['isGuild']),
    logo: ({ cluster }) => cluster.logo || '', // peñistas si el sport orgnaization es guild de los contario suscriptores
    filteredSidebarItems({ sidebarItems, isGuild }) {
      return sidebarItems
        .filter(item => item.id === 'publish' ? !this.cluster.published : true)
        .map(item => item.id === 'players' ? { ...item, name: `project.sections.players-${isGuild ? 'guild' : 'suscribers'}` } : item)
        .map(item => item.id === 'publish' ? { ...item, action: this.publish } : item)
    },
  },
  methods: {
    async publish() {
      const { organizationId, clusterId } = this
      this.runAsync(() => this.$store.dispatch('cluster/publish', { organizationId, clusterId }))
    },
  },
}
</script>
